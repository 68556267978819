import axios from '../../plugins/axios'
import router from '@/router/index'
export default {
  namespaced: true,
  state: {
    auth: {
    },
    loggedIn: false
  },
  mutations: {
    setLoggedIn: (state, payload) => {
      state.loggedIn = payload
    },
    setAuth: (state, payload) => {
      state.auth = payload
    },
    setProfileImage: (state, payload) => {
      state.auth.profileImage = payload
    }
  },
  actions: {
    changeProfileImage: ({ commit }, payload) => {
      commit('setProfileImage', payload)
    },
    login: async ({ commit }, payload) => {
      commit('setAuth', payload)
      commit('setLoggedIn', true)
    },
    logout: async ({ commit }) => {
      const url = '/logout'
      await axios.post(url, {}).then(res => {
        if (res) {
          commit('setAuth', {})
          commit('setLoggedIn', false)
          router.push('/')
        }
      })
    },
    refreshUser: async ({ commit }) => {
      const url = '/user'
      await axios.get(url).then(res => {
        if (res.data) {
          if (typeof res.data === 'object') {
            commit('setAuth', res.data)
            commit('setLoggedIn', true)
          } else {
            commit('setAuth', {})
            commit('setLoggedIn', false)
          }
        } else {
          commit('setAuth', {})
          commit('setLoggedIn', false)
        }
      }).catch(() => {
        commit('setAuth', {})
        commit('setLoggedIn', false)
      })
    }
  }
}
