<template>
  <v-dialog v-model="showDrawer" width="100%" fullscreen height="100%" transition="dialog-top-transition">
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" icon="mdi-menu" variant="text"/>
    </template>
    <template v-slot:default="{ isActive }">
      <default-layer
              :layerStyle="drawerStyle"
      >
        <template v-slot:title>
          <v-row>
            <v-col cols="6">
              <v-btn
                      flat
                      color="transparent"
                      :icon="true"
                      @click="isActive.value = false"
              >
                <v-icon color="white">
                  mdi-close
                </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6">
            </v-col>
          </v-row>
        </template>
        <template v-slot:content>
          <template v-if="isAuth">
            <div class="mb-3">
              <div class="text-center">
                <v-avatar
                        :image="user.profileImage ? user.profileImage : require('@/assets/images/sample/user/no-profile-image.jpg')"
                        size="80"/>
              </div>
              <div class="text-center mt-5">
                <h3 class="text-h6 font-weight-black nanumgothic">
                  {{ user.username }}
                </h3>
                <p class="text-brown-lighten-3 nanumgothic">
                  @{{ user.franchise ? user.franchise.businessName : user.id }}
                </p>
              </div>
            </div>
          </template>
          <template v-else>
            <v-list
                    :style="{
                      backgroundColor: 'transparent'
                    }"
                    theme="dark"
            >
              <template v-for="(item, i) in loginMenu">
                <template v-if="item.show">
                  <v-list-item
                          :key="'login-menu-list-' + i"
                          :value="item"
                          color="#fff"
                          @click="item.method"
                  >
                    <template v-slot:prepend>
                      <v-icon :icon="item.icon" color="white"/>
                    </template>
                    <template v-slot:title>
                      <p class="text-white gmarket-sans-medium text-12">
                        {{ item.title }}
                      </p>
                    </template>
                  </v-list-item>
                </template>
              </template>
            </v-list>
          </template>
          <v-divider color="white" />
          <div>
            <v-list
                    :style="{
                      backgroundColor: 'transparent'
                    }"
                    theme="dark"
            >
              <template v-for="(item, i) in menuList">
                <template v-if="item.show">
                  <v-list-item
                          v-if="!item.subMenu"
                          :key="'menu-list-' + i"
                          :value="item"
                          color="#fff"
                          :to="item.link || ''"
                  >
                    <template v-slot:prepend>
                      <v-icon :icon="item.icon" color="white"/>
                    </template>
                    <template v-slot:title>
                      <p class="text-white gmarket-sans-medium text-12">
                        {{ item.title }}
                      </p>
                    </template>
                  </v-list-item>
                  <v-list-group
                          v-else
                          :key="'left-menu-list-' + i"
                          :value="item"
                          color="#fff"
                  >
                    <template #activator="{ props }">
                      <v-list-item v-bind="props">
                        <template v-slot:prepend>
                          <v-icon :icon="item.icon" color="white"/>
                        </template>
                        <template v-slot:title>
                          <p class="text-white gmarket-sans-medium text-12">
                            {{ item.title }}
                          </p>
                        </template>
                      </v-list-item>
                    </template>
                    <v-list-item
                            v-for="(subItem, j) in item.subMenu"
                            :key="'menu-list-' + i + '' + j"
                            :to="subItem.link || ''"
                    >
                      <template #title>
                        <p class="text-white text-13 gmarket-sans-medium">
                          {{ subItem.title }}
                        </p>
                      </template>
                    </v-list-item>
                  </v-list-group>
                </template>
              </template>
            </v-list>
          </div>
        </template>
      </default-layer>
    </template>
  </v-dialog>
</template>

<script>
import DefaultLayer from '@/components/layer/DefaultLayer.vue'
import { mapState } from 'vuex'

export default {
  name: 'LeftMenu',
  components: { DefaultLayer },
  data () {
    return {
      showDrawer: false,
      drawerStyle: {
        background: 'rgba(45, 27, 7, 0.9)',
        backdropFilter: 'blur(6px)',
        color: 'white'
      },
      user: {}
    }
  },
  computed: {
    ...mapState('auth', ['auth']),
    isAuth () {
      return !!(this.auth && Object.keys(this.auth).length > 0)
    },
    loginMenu () {
      return [
        {
          title: 'Login',
          icon: 'mdi-login',
          method: this.goLoginPage,
          show: !this.isAuth
        },
        {
          title: 'SignUp',
          icon: 'mdi-account-plus',
          method: this.goSignUpPage,
          show: !this.isAuth
        }
      ]
    },
    menuList () {
      return [
        {
          title: 'Home',
          icon: 'mdi-home',
          link: '/home',
          show: true
        },
        {
          title: 'VO.HA.B',
          icon: 'mdi-alpha-v-box',
          link: '/company',
          show: true
        },
        {
          title: 'Community',
          icon: 'mdi-account-voice',
          link: '/community/1',
          show: true
        },
        {
          title: 'Manager Lounge',
          icon: 'mdi-human-male-board-poll',
          link: '/manage/community/1',
          show: this.auth && (this.auth.usersAuth === 'A' || this.auth.usersAuth === 'M' || this.auth.usersJobTitle === 'director' || this.auth.usersJobTitle === 'manager')
        },
        {
          title: 'Styles',
          icon: 'mdi-image-multiple-outline',
          link: '/styles',
          show: true
        },
        {
          title: 'Products',
          icon: 'mdi-content-cut',
          link: '/products',
          show: true
        },
        {
          title: 'Settings',
          icon: 'mdi-cog',
          link: '/setting',
          show: this.isAuth
        },
        {
          title: 'Manage',
          icon: 'mdi-account-cog',
          link: '/manage',
          show: this.isAuth && this.auth.usersAuth === 'A' && !!this.auth.franchise
        }
      ]
    }
  },
  watch: {
    '$route' (to, from) {
      this.showDrawer = false
    },
    showDrawer () {
      this.user = this.auth
    }
  },
  mounted () {
    this.user = this.auth
  },
  methods: {
    goLoginPage () {
      window.location.href = '/login'
    },
    goSignUpPage () {
      this.$router.push('/signup')
    },
    isShowMenu (menu) {
      if (this.auth.usersAuth === 'A') {
        return !!this.auth.franchise
      } else {
        return !!menu.showUser
      }
    }
  }
}
</script>

<style scoped>

</style>
