import axi from 'axios'
import store from '@/store'
const axios = axi.create({
  baseURL: '/api',
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json;charset=utf-8'
  },
  withCredentials: 'include'
})
axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response && error.response.status) {
      if (error.response.status === 401 || error.response.status === 403) {
        store.commit('auth/setAuth', {})
        store.commit('auth/setLoggedIn', false)
        return new Promise(() => {
          window.location.href = '/logout'
        })
      } else {
        return Promise.reject(error)
      }
    } else {
      return Promise.reject(error)
    }
  }
)

export default axios
