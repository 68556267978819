<template>
  <div class="default-wrap" :style="{ height: $isMobile() ? '93%' : '97%' }">
    <top-nav-bar/>
    <div id="router-wrap" class="no-scroll">
      <default-content/>
    </div>
    <bottom-nav-bar/>
  </div>
</template>

<script>
import DefaultContent from '@/layout/DefaultContent.vue'
import TopNavBar from '@/components/navigation/TopNavBar.vue'
import BottomNavBar from '@/components/navigation/BottomNavBar.vue'

export default {
  name: 'DefaultLayout',
  components: {
    BottomNavBar,
    TopNavBar,
    DefaultContent
  }
}
</script>

<style scoped lang="scss">
#router-wrap {
  background-color: #fff;
  height: 90%;
  box-shadow: 0px -3px 15px 1px #595959;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.default-wrap {
  height: 93%
}
</style>
