<template>
  <div class="h-100">
    <v-row class="h-100 overflow-hidden">
      <v-col lg="2" md="3" cols="12" class="py-0 pt-md-3 d-none h-100 d-md-block">
        <styles-left-menu :albums="albums"/>
      </v-col>
      <v-col lg="10" md="9" cols="12" class="py-0 pt-md-3 pl-md-0 pl-3 h-100">
        <div class="pr-3 py-1 pt-md-0 h-100 pl-3 pl-md-0">
          <v-sheet
                  height="100%"
                  width="100%"
                  rounded="lg"
                  class="pt-3 px-1 overflow-y-auto overflow-x-hidden no-scroll"
          >
            <v-row :style="{ height: '44px' }">
              <v-col cols="6" class="text-left py-0">
                <v-btn v-if="isShowPrev" flat size="large" rounded="0" @click="prevPage">
                  <v-icon>
                    mdi-chevron-left
                  </v-icon>
                  이전
                </v-btn>
              </v-col>
              <v-col cols="6" class="text-right py-0">
                <v-btn v-if="isShowNext" flat size="large" rounded="0" @click="nextPage">
                  다음
                  <v-icon>
                    mdi-chevron-right
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="album && album.requireAuth && !isAuth">
              <v-col cols="12" class="py-15 mt-15 text-center">
                <p>
                  해당 앨범을 확인하시려면 로그인이 필요합니다.
                </p>
                <v-btn theme="dark" class="rounded-0 mt-3" @click="moveToLogin">
                  로그인
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col v-for="(item, i) in pagination.list" :key="'style-image-' + i" xl="2" lg="2" md="3" sm="6"
                     cols="12" class="pa-1">
                <div class="d-flex justify-center align-start w-100 h-100">
                  <v-img
                          :src="item.imageSrc"
                          :lazy-src="item.imageSrc"
                          :aspect-ratio="9/16"
                          class="styles-images"
                          cover
                          max-height="280px"
                          @click="openImageDetail(item)"
                  />
                </div>
              </v-col>
              <v-col v-if="!pagination || pagination.list.length === 0" cols="12" class="py-15 mt-15 text-center">
                등록된 스타일이 없습니다.
              </v-col>
            </v-row>
          </v-sheet>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="showImageDetail" v-if="selectedImage" :max-width="$isMobile() ? '100%' : 500"
              :fullscreen="$isMobile()" transition="dialog-bottom-transition">
      <styles-detail-card :selected-image="selectedImage" @close-dialog="closeImageDetail"/>
    </v-dialog>
    <div :style="{ position: 'absolute', left: '20px', bottom: '20px' }" class="d-block d-md-none">
      <v-dialog v-model="showFilter" fullscreen>
        <template #activator="{ props }">
          <v-btn v-bind="props" icon="mdi-filter" color="grey-darken-4"/>
        </template>
        <template #default="{ isActive }">
          <v-card>
            <v-card-title>
              <v-row>
                <v-col cols="6">
                  <v-btn
                          flat
                          color="transparent"
                          icon
                          size="small"
                          @click="isActive.value = false"
                  >
                    <v-icon>
                      mdi-chevron-left
                    </v-icon>
                  </v-btn>
                  <span class="text-14">
                    Styles
                  </span>
                </v-col>
                <v-col cols="6" class="d-flex align-center justify-end">
                  <h5 class="text-14 font-weight-medium pr-3 lh-14">
                    스타일유형
                  </h5>
                </v-col>
              </v-row>
            </v-card-title>
            <v-card-text>
              <v-row class="px-1">
                <h5 class="text-14 mb-1 font-weight-medium">
                  기본 스타일
                </h5>
                <v-divider color="grey-darken-3 px-1"/>
              </v-row>
              <v-row class="mt-5">
                <v-col v-for="(item, i) in albums" :key="'left-menu-' + i" cols="6" class="pa-1">
                  <v-btn :to="item.link" @click="isActive.value = false" variant="outlined" class="w-100 px-0" color="grey"
                         rounded="0" :style="{ display: 'block', height: '36px', lineHeight: '36px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }">
                    <span class="text-12 text-grey-darken-2 d-block">
                      {{ item.albumName }}
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </template>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import StylesLeftMenu from '@/components/styles/StylesLeftMenu.vue'
import StylesDetailCard from '@/components/styles/StylesDetailCard.vue'
import { mapState } from 'vuex'

export default {
  name: 'StylesView',
  components: {
    StylesDetailCard,
    StylesLeftMenu
  },
  data () {
    return {
      showFilter: false,
      showImageDetail: false,
      selectedImage: null,
      albums: [],
      pagination: {
        list: []
      },
      album: {},
      pageNum: this.$route.params.pageNum ? parseInt(this.$route.params.pageNum) : 1,
      pageDataSize: this.$route.query.pageDataSize || 18,
      styleUrl: '/styles/1?albumIdx='
    }
  },
  beforeMount () {
    this.getPagination()
    this.getAlbums()
    this.getAlbum()
  },
  computed: {
    ...mapState('auth', ['auth']),
    isAuth () {
      return !!(this.auth && Object.keys(this.auth).length > 0)
    },
    styleIdx () {
      return this.$route.query ? this.$route.query.styleIdx : null
    },
    albumIdx () {
      return this.$route.query ? this.$route.query.albumIdx : null
    },
    isShowPrev () {
      return this.pageNum !== 1
    },
    isShowNext () {
      if (this.pageNum < this.pagination.maxPageNum) {
        return true
      } else {
        return false
      }
    },
    path () {
      return this.$route.fullPath
    }
  },
  watch: {
    albumIdx () {
      this.pageNum = 1
      this.getPagination(1)
      this.getAlbum()
    },
    pageNum () {
      this.$router.push({
        path: `/styles/${this.pageNum}`,
        query: this.$route.query
      })
    },
    path () {
      this.getPagination(this.$route.params.pageNum)
    }
  },
  methods: {
    nextPage () {
      this.pageNum = this.pageNum + 1
    },
    prevPage () {
      this.pageNum = this.pageNum - 1
    },
    moveToLogin () {
      window.location.href = '/login'
    },
    async getAlbum () {
      await this.$axios.get(`/style/album/detail/${this.albumIdx}`).then(res => {
        if (res.data) {
          this.album = res.data
        }
      })
    },
    async getAlbums () {
      await this.$axios.get('/style/album/list').then(res => {
        if (res.data) {
          this.albums = res.data
          let count = 0
          for (const a of this.albums) {
            if (!this.albumIdx && count === 0) {
              this.$router.push(this.styleUrl + a.idx)
            }
            a.link = this.styleUrl + a.idx
            count++
          }
        }
      })
    },
    async getPagination (pageNum) {
      const params = {
        pageNum: pageNum || this.pageNum,
        pageDataSize: this.pageDataSize,
        idx: this.styleIdx
      }
      if (this.albumIdx) {
        params.albumIdx = this.albumIdx
      }
      await this.$axios.get('/style/list', { params: params }).then(res => {
        if (res.data) {
          this.pagination = res.data
        }
      })
    },
    openImageDetail (image) {
      this.selectedImage = image
      this.showImageDetail = true
    },
    closeImageDetail () {
      this.showImageDetail = false
      setTimeout(() => {
        this.selectedImage = null
      }, 300)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
