<template>
  <div class="bg-grey-lighten-4 h-100 mt-3">
    <v-row justify="center">
      <v-col cols="12" class="pb-2 pt-4 d-flex align-center">
        <h5 class="text-grey-darken-4 font-weight-light px-4">커뮤니티</h5>
        <v-spacer />
        <div class="d-flex px-3">
          <v-dialog v-if="isAuth" v-model="showRegister1" width="800px" :fullscreen="$isMobile()" transition="dialog-bottom-transition">
            <template #activator="{ props }">
              <v-btn v-bind="props" flat rounded="0" size="small">
                글 작성
              </v-btn>
            </template>
            <board-register @refresh-pagination="getPagination(1)" @close="closeRegister" />
          </v-dialog>
        </div>
      </v-col>
      <v-divider color="grey" />
      <v-col col="12" sm="6" offset-sm="6" class="d-flex px-8 pl-md-3 pr-md-5">
        <v-select
                v-model="condition"
                :items="conditions"
                variant="underlined"
                hide-details
                density="compact"
                class="w-25 mr-2"
        />
        <v-text-field
                v-model="keyword"
                class="w-75"
                variant="underlined"
                append-inner-icon="mdi-magnify"
                placeholder="Keyword"
                hide-details
                density="compact"
                @keyup="enterSearch"
                @click:append-inner="search"
        />
      </v-col>
      <v-col cols="12" class="bg-white py-0">
        <v-list class="py-0">
          <v-list-item v-for="item in pagination.list" :key="`board-${item.idx}`" class="pa-0" :style="{ minHeight: 'initial' }">
            <v-dialog v-model="item.showPopup" width="800px" persistent :fullscreen="$isMobile()" transition="dialog-bottom-transition">
              <template #activator="{ props }">
                <div v-bind="props" class="d-flex justify-start align-center py-3 px-5 h-100 cursor-pointer" :style="{ backgroundColor: item.pinned ? '#efefef' : 'white' }">
                  <p class="text-13 text-grey-darken-2 w-75 text-truncate">
                    <v-icon v-if="item.secret" size="x-small" color="grey-darken-2" class="mr-2">
                      mdi-lock-outline
                    </v-icon>
                    <span :class="{ 'font-weight-bold': item.pinned }">
                      {{ (item.pinned ? '[공지글] ' : '') +  item.title }}
                    </span>
                    <span v-if="!item.pinned">
                      ({{ item.replyCount }})
                    </span>
                  </p>
                  <p class="text-13 text-grey-darken-1 text-truncate text-right w-25">
                    <v-tooltip :text="item.usersId" location="bottom">
                      <template #activator="{ props }">
                        <span v-bind="props" class="font-weight-bold">
                          {{ item.username }}
                        </span>
                      </template>
                    </v-tooltip>
                    ·
                    {{ $parseDate(item.date, 'yyyy.MM.DD HH:mm') }}
                  </p>
                </div>
              </template>
              <board-popup :board-idx="item.idx" @close="item.showPopup = !item.showPopup" @refresh-pagination="getPagination(1)" />
            </v-dialog>
            <v-divider class="pa-0" />
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="12">
        <div class="d-flex px-3">
          <v-spacer/>
          <v-pagination
                  v-model="pageNum"
                  active-color="grey-darken-3"
                  color="grey-lighten-1"
                  :length="pagination.maxPageNum"
                  :total-visible="10"
                  rounded="xl"
                  variant="plain"
                  density="compact"
          />
          <v-spacer />
          <v-dialog v-if="isAuth" v-model="showRegister2" width="800px" :fullscreen="$isMobile()" transition="dialog-bottom-transition">
            <template #activator="{ props }">
              <v-btn v-bind="props" flat rounded="0" size="small">
                글 작성
              </v-btn>
            </template>
            <board-register @refresh-pagination="getPagination(1)" @close="closeRegister" />
          </v-dialog>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import BoardRegister from '@/components/board/BoardRegister.vue'
import BoardPopup from '@/components/board/BoardPopup.vue'
import { mapState } from 'vuex'

export default {
  name: 'BoardView',
  components: { BoardPopup, BoardRegister },
  data () {
    return {
      showRegister1: false,
      showRegister2: false,
      showSearchLayer: false,
      pagination: {
        list: []
      },
      pageNum: this.$route.params.pageNum ? parseInt(this.$route.params.pageNum) : 1,
      pageDataSize: this.$route.query.pageDataSize || 20,
      keyword: this.$route.query.keyword || '',
      condition: this.$route.query.condition || '',
      conditions: [
        {
          title: '전체',
          value: ''
        },
        {
          title: '제목',
          value: 'title'
        },
        {
          title: '내용',
          value: 'content'
        }
      ]
    }
  },
  computed: {
    ...mapState('auth', ['auth']),
    isAuth () {
      return !!(this.auth && Object.keys(this.auth).length > 0)
    },
    path () {
      return this.$route.fullPath
    }
  },
  watch: {
    pageNum () {
      this.$router.push({ path: `/community/${this.pageNum}`, query: this.$route.query })
    },
    path () {
      this.getPagination(this.$route.params.pageNum)
    }
  },
  beforeMount () {
    this.getPagination()
  },
  methods: {
    async getPagination (pageNum) {
      const params = {
        pageNum: pageNum || this.pageNum,
        pageDataSize: this.pageDataSize,
        keyword: this.keyword,
        condition: this.condition
      }
      await this.$axios.get('/board/list', { params: params }).then(res => {
        this.pagination = res.data
      })
      this.closeRegister()
    },
    enterSearch () {
      if (event.keyCode === 13) {
        this.search()
      }
    },
    search () {
      const query = {
        pageDataSize: this.pageDataSize,
        keyword: this.keyword,
        condition: this.condition
      }
      this.$router.push({ path: '/community/1', query: query })
      this.getPagination(1)
    },
    closeRegister () {
      this.showRegister1 = false
      this.showRegister2 = false
    }
  }
}
</script>

<style lang="scss">
  .v-menu *, .v-select__selection-text, .v-field__input {
    font-size: 13px !important;
  }
</style>
