import routes from './routes'
import store from '../store'

import { createRouter, createWebHashHistory } from 'vue-router'

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  store.dispatch('auth/refreshUser')
  const auth = store.state.auth.auth
  if (to.matched.some(record => record.meta.requireAuth)) {
    if (isEmpty(auth)) {
      window.location.href = '/login'
    } else {
      next()
    }
  } else if (to.path === 'user-term' || to.path === 'privacy-term') {
    next()
  } else if (to.path === '/login') {
    window.location.href = '/login'
  } else if (to.path === '/signup') {
    if (!isEmpty(auth)) {
      next('/home')
    } else {
      next()
    }
  } else if (to.path.startsWith('/manage')) {
    if (auth.usersAuth !== 'A') {
      next(from && from.path ? from.path : '/home')
    } else {
      next()
    }
  } else {
    store.dispatch('auth/refreshUser')
    next()
  }
})

const isEmpty = obj => {
  return Object.keys(obj).length === 0
}

export default router
