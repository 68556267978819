<template>
  <nav id="top-navbar">
    <v-row class="flex-nowrap">
      <v-col cols="4" class="text-left text-no-wrap pr-0">
        <left-menu-layer/>
      </v-col>
      <v-col cols="4" class="text-right py-0 text-no-wrap px-0">
        <div class="d-flex justify-center align-center h-100">
          <v-img :src="require('@/assets/images/logo.png')" :lazy-src="require('@/assets/images/logo.png')"
                 height="50px" @click="$router.push('/home')"/>
        </div>
      </v-col>
      <v-col cols="4" class="text-right text-no-wrap pl-0">
        <div class="d-flex justify-end align-center">
          <search-layer v-if="isAuth" />
          <alarm-layer v-if="isAuth" />
        </div>
      </v-col>
    </v-row>
  </nav>
</template>

<script>
import LeftMenuLayer from '@/components/navigation/LeftMenuLayer.vue'
import SearchLayer from '@/components/navigation/SearchLayer.vue'
import AlarmLayer from '@/components/navigation/AlarmLayer.vue'
import { mapState } from 'vuex'

export default {
  name: 'TopNavBar',
  components: {
    AlarmLayer,
    SearchLayer,
    LeftMenuLayer
  },
  data () {
    return {}
  },
  computed: {
    ...mapState('auth', ['auth']),
    isAuth () {
      return !!(this.auth && Object.keys(this.auth).length > 0)
    }
  }
}
</script>

<style scoped lang="scss">
nav {
  padding: 10px 10px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

#top-navbar {
  background-color: #fff;
  z-index: 1;
  position: relative;
}
</style>
