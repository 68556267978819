<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-sheet class="px-2 pr-md-0 pl-md-15 py-5">
        <h3 :style="{ color: '#3e3e3e' }">
          VO.HA.B 소개
        </h3>
        <v-divider class="w-100 mb-3" />
        <div class="introduce-form" v-html="settings.introduce" />
      </v-sheet>
    </v-col>
    <v-col cols="12" md="6">
      <v-sheet class="px-2 pr-md-15 pl-md-0 py-5 curriculum">
        <h3 :style="{ color: '#3e3e3e' }">
          교육과정
        </h3>
        <v-divider class="w-100 mb-3" />
        <v-expansion-panels v-model="curriculumExpansion" variant="accordion">
          <v-expansion-panel v-for="item in curriculums" :key="item.idx" class="instructor-info" elevation="0">
            <template #title>
              <div class="d-flex align-center font-weight-bold">
                <p class="ml-3">
                  {{ item.curriculumName }}
                </p>
              </div>
            </template>
            <template #text>
              <div class="instructor-profile no-scroll pa-3" v-html="item.curriculumContent" />
            </template>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-sheet>
    </v-col>
    <v-col cols="12" md="6">
      <v-sheet class="px-2 pr-md-0 pl-md-15 py-5">
        <h3 :style="{ color: '#3e3e3e' }">
          강사 소개
        </h3>
        <v-divider class="w-100 mb-3" />
        <v-expansion-panels v-model="expansion" variant="accordion">
          <v-expansion-panel v-for="item in instructors" :key="item.idx" class="instructor-info" elevation="0">
            <template #title>
              <div class="d-flex align-center font-weight-bold">
                <v-img :src="item.instructorMainImage" class="" width="50px" height="50px" max-width="50px" max-height="50px" cover rounded="circle" :lazy-src="item.instructorMainImage" />
                <p class="ml-3">
                  {{ item.instructorName }}
                </p>
                <v-img v-if="item.instructorInstagram" :src="getSnsIconStyle(item.instructorInstagram)" :lazy-src="getSnsIconStyle(item.instructorInstagram)" class="ml-3" width="30px" @click="openSnsLink(item.instructorInstagram)" />
              </div>
            </template>
            <template #text>
              <div class="instructor-profile no-scroll pa-3" v-html="item.instructorProfile" />
            </template>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-sheet>
    </v-col>
    <v-col cols="12" md="6">
      <v-sheet class="px-2 pr-md-15 pl-md-0 py-5">
        <h3 :style="{ color: '#3e3e3e' }">
          오시는 길
        </h3>
        <v-divider class="w-100 mb-3" />
        <v-row>
          <v-col cols="12">
            <v-img :src="require('@/assets/images/introduce.png')" rounded="lg" />
          </v-col>
          <v-col cols="12">
            <v-img :src="require('@/assets/images/introduce-2.jpg')" rounded="lg" />
          </v-col>
        </v-row>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'IntroduceView',
  data () {
    return {
      expansion: 0,
      curriculumExpansion: 0,
      instructors: [],
      curriculums: []
    }
  },
  computed: {
    ...mapState('site', ['settings'])
  },
  beforeMount () {
    this.getList()
    this.getCurriculums()
  },
  methods: {
    async getCurriculums () {
      await this.$axios.get('/curriculum/list').then(res => {
        if (res.data) {
          this.curriculums = res.data
        }
      })
    },
    async getList () {
      await this.$axios.get('/instructor/list').then(res => {
        if (res.data) {
          this.instructors = res.data
        }
      })
    },
    getSnsIconStyle (link) {
      if (link.indexOf('facebook') > -1) {
        return require('@/assets/images/icon/facebook_icon.png')
      } else if (link.indexOf('youtu.be') > -1 || link.indexOf('youtube') > -1) {
        return require('@/assets/images/icon/youtube_icon.png')
      } else if (link.indexOf('instagram') > -1) {
        return require('@/assets/images/icon/instagram_icon.png')
      } else {
        return require('@/assets/images/icon/url_icon.png')
      }
    },
    openSnsLink (link) {
      window.open(link, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
.introduce-form {
  min-height: 400px;
  padding: 10px 0;
  img {
    max-width: 100%;
  }
}
</style>
<style lang="scss">
.curriculum {
  img {
    max-width: 100%;
  }
}
.introduce-form {
  img {
    max-width: 100%;
  }
}
.instructor-info {
  .v-expansion-panel-text__wrapper {
    padding: 0 !important;
  }
  .instructor-profile {
    position: relative;
    max-height: 600px;
    overflow: auto;

    img {
      max-width: 100%;
    }
  }
}
</style>
