<template>
  <div id="bottom-nav-bar" class="d-flex align-center" :style="{ height: '70px' }">
    <div class="text-center flex-grow-1 d-flex justify-center">
      <router-link to="/home" class="text-grey-darken-3 w-100 h-100" :class="$isMobile() ? 'text-13' : ''">
        Home
      </router-link>
    </div>
    <div class="text-center flex-grow-1 d-flex justify-center">
      <router-link to="/styles/1" class="text-grey-darken-3 w-100 h-100" :class="$isMobile() ? 'text-13' : ''">
        Styles
      </router-link>
    </div>
    <div class="text-center flex-grow-1 d-flex justify-center">
      <router-link to="/community/1" class="text-grey-darken-3 w-100 h-100" :class="$isMobile() ? 'text-13' : ''">
        Community
      </router-link>
    </div>
    <div class="text-center flex-grow-1 d-flex justify-center">
      <router-link to="/products/1" class="text-grey-darken-3 w-100 h-100" :class="$isMobile() ? 'text-13' : ''">
        Products
      </router-link>
    </div>
    <div v-if="isAuth" class="text-center flex-grow-1 d-flex justify-center">
      <router-link to="/setting" class="text-grey-darken-3 w-100 h-100" :class="$isMobile() ? 'text-13' : ''">
        Settings
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BottomNavBar',
  computed: {
    ...mapState('auth', ['auth']),
    isAuth () {
      return !!(this.auth && Object.keys(this.auth).length > 0)
    }
  }
}
</script>

<style scoped lang="scss">
#bottom-nav-bar {
  height: 70px;
}
.w-20 {
  width: 20%;
}
</style>
<script setup>
</script>
