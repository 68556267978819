<template>
  <div class="h-100 px-5">
    <v-row class="h-100">
      <v-col cols="12" class="py-0 pt-md-10">
        <v-row v-if="productTypes && productTypes.length > 0">
          <template v-for="item in productTypes" :key="`product-type-${item.idx}`">
            <v-col lg="4" md="4" cols="12" class="position-relative cursor-pointer pa-1"
                   @click="$router.push(item.link)">
              <div class="position-relative product-type-card">
                <div :id="`product-${item.idx}`" :style="getCardStyle(item)"/>
                <img alt="temp-product-image" :src="item.firstProduct ? item.firstProduct.imageSrc : '/no-image.jpg'" class="d-none" @error="changeProductBackground(item)" />
                <div class="product-type-card-overlay d-flex justify-center align-center" />
                <div class="w-100 text-white zIndex-1 justify-end d-flex align-end" :style="{ height: '220px' }">
                  <h5 class="nanumgothic pr-2 pb-2" :style="{ fontSize: '20px', fontWeight: 600 }">
                    {{ item.productTypeName }}
                  </h5>
                </div>
              </div>
            </v-col>
          </template>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'ProductsHome',
  data () {
    return {
      productTypes: [],
      productUrl: '/products/1?productTypeIdx='
    }
  },
  beforeMount () {
    this.getProductTypes()
  },
  methods: {
    changeProductBackground (product) {
      document.getElementById(`product-${product.idx}`).style.backgroundImage = 'url(/no-image.jpg)'
    },
    async getProductTypes () {
      await this.$axios.get('/product/type/list').then(res => {
        if (res.data) {
          this.productTypes = res.data
          for (const p of this.productTypes) {
            p.link = this.productUrl + p.idx
          }
        }
      })
    },
    getCardStyle (productType) {
      const style = {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: '0',
        top: '0'
      }
      if (productType && productType.firstProduct) {
        style.backgroundImage = `url('${productType.firstProduct.imageSrc}')`
        style.backgroundSize = 'cover'
        style.backgroundPosition = 'center'
      } else {
        style.backgroundImage = 'url(/no-image.jpg)'
        style.backgroundSize = 'cover'
        style.backgroundPosition = 'center'
      }
      return style
    }
  }
}
</script>

<style scoped lang="scss">
.product-type-card {
  overflow: hidden;

  .product-type-card-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: black;
    opacity: 0.5;

    .empty-icon {
      opacity: 0.1;
    }
  }
}

</style>
