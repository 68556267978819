<template>
  <default-layer class="card-text-px-0 card-text-py-0">
    <template #title>
      <template v-if="$isMobile()">
        <v-row>
          <v-col cols="6" class="d-flex align-center">
            <v-btn flat color="transparent" icon="mdi-chevron-left" size="small" @click="close" />
            <div class="text-14">
              Community
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row>
          <v-col cols="6" class="d-flex align-center justify-start">
            <h5 class="text-14 font-weight-medium pl-3 lh-14">
              Community
            </h5>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn
              flat
              size="small"
              @click="close"
            >
              Close
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </template>
    <template #content>
      <v-row>
        <v-col cols="12">
          <div class="px-5 d-flex align-center">
            <v-icon v-if="board.secret" color="grey-darken-2" size="small" class="mr-2">
              mdi-lock-outline
            </v-icon>
            <h5 class="text-14 font-weight-medium">
              {{ (board.pinned ? '[공지글] ' : '') +  board.title }}
            </h5>
          </div>
          <div class="px-5 d-flex align-center justify-end text-13 text-grey-darken-2">
            <span class="font-weight-bold">{{ board.username }}</span>
            <span class="mx-1">·</span>
            <span>{{ $parseDate(board.date, 'yyyy.MM.DD HH:mm')}}</span>
            <template v-if="board.owner">
              <span class="ml-1">·</span>
              <v-dialog v-model="showModify" :fullscreen="$isMobile()">
                <template #activator="{ props }">
                  <v-btn v-bind="props" color="transparent" flat size="small" class="px-1 min-width-0 min-height-0">수정</v-btn>
                </template>
                <board-register :board="board" @close="showModify = !showModify" @refresh-pagination="refresh"/>
              </v-dialog>
              <v-btn color="transparent" flat size="small" class="px-1 min-width-0 min-height-0" @click="deleteBoard">삭제</v-btn>
            </template>
          </div>
          <div ref="boardContent" class="pa-5 text-13 board-content" :style="{ minHeight: '200px' }" v-html="board.content" />
        </v-col>
      </v-row>
      <reply-layer v-if="showReplyLayer && !board.pinned && board.owner" :board="board" />
      <v-dialog v-model="showImageDetail" max-width="100%" max-height="70%">
        <v-card class="pa-0">
          <v-card-text class="pa-0">
            <v-img :src="selectedImage" width="100%" />
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
  </default-layer>
</template>

<script>
import DefaultLayer from '@/components/layer/DefaultLayer.vue'
import ReplyLayer from '@/components/board/ReplyLayer.vue'
import BoardRegister from '@/components/board/BoardRegister.vue'

export default {
  name: 'BoardPopup',
  components: { BoardRegister, ReplyLayer, DefaultLayer },
  props: ['boardIdx'],
  data () {
    return {
      board: {},
      showModify: false,
      showReplyLayer: false,
      showImageDetail: false,
      selectedImage: ''
    }
  },
  beforeMount () {
    if (this.boardIdx) {
      this.getBoard()
    }
  },
  watch: {
    selectedImage (src) {
      if (src) {
        this.showImageDetail = true
        console.log(this.selectedImage)
      }
    },
    showImageDetail (isShow) {
      if (!isShow) {
        this.selectedImage = ''
      }
    }
  },
  methods: {
    imageEventInject () {
      const images = this.$refs.boardContent.querySelectorAll('img')
      images.forEach(img => {
        img.addEventListener('click', () => {
          this.selectedImage = img.src
        })
      })
    },
    async getBoard () {
      await this.$axios.get(`/board/detail/${this.boardIdx}`).then(res => {
        this.board = res.data
        this.showReplyLayer = true
        setTimeout(() => {
          this.imageEventInject()
        }, 100)
      })
    },
    async deleteBoard () {
      if (confirm('게시글 및 댓글이 모두 삭제됩니다.\n게시글을 삭제하시겠습니까?')) {
        await this.$axios.delete(`board?idx=${this.boardIdx}`).then(() => {
          this.refresh()
          this.close()
        }).catch(() => {
          alert('게시글 삭제 중 오류가 발생했습니다.')
        })
      }
    },
    close () {
      this.$emit('close')
    },
    refresh () {
      this.$emit('refresh-pagination')
    }
  }
}
</script>

<style lang="scss">
.board-content {
  img {
    max-width: 100% !important;
    box-shadow: 0 0 12px 3px #f0f0f0;
    border-radius: 3px;
  }
}
</style>
